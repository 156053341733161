import { inject, Injectable } from "@angular/core";
import { Action, Selector, State, StateContext, Store } from "@ngxs/store";
import {
  GetClinicStatisticsResponse,
  GetSchoolStatisticsResponse,
  UserGroup,
} from "@platform-app/app/core/api/models";
import { DashboardService } from "@platform-app/app/core/api/services";
import { AuthState } from "@platform-app/app/core/auth/auth.state";
import { GetOrganizationStatistics } from "@platform-app/app/main/dashboard-page/stats-panel/state/dashboard-statistics.actions";
import { StateToDefaultResetter } from "@platform-app/app/main/shared/state/state-to-default-resetter";
import { finalize, iif, tap } from "rxjs";

export interface DashboardStatisticsStateModel {
  dataLoading: boolean;
  organizationStatisticsResponse:
    | GetSchoolStatisticsResponse
    | GetClinicStatisticsResponse
    | null;
}

const DEFAULT_DASHBOARD_STATE: DashboardStatisticsStateModel = {
  dataLoading: false,
  organizationStatisticsResponse: null,
};

@State<DashboardStatisticsStateModel>({
  name: "Dashboard",
  defaults: DEFAULT_DASHBOARD_STATE,
})
@Injectable()
export class DashboardStatisticsState extends StateToDefaultResetter<DashboardStatisticsStateModel> {
  private readonly store = inject(Store);
  private readonly dashboardService = inject(DashboardService);

  constructor() {
    super(DEFAULT_DASHBOARD_STATE);
  }

  @Selector()
  static schoolStatistics(state: DashboardStatisticsStateModel) {
    return state.organizationStatisticsResponse;
  }

  @Action(GetOrganizationStatistics)
  getOrganizationStatistisc(ctx: StateContext<DashboardStatisticsStateModel>) {
    ctx.patchState({ dataLoading: true });

    const user = this.store.selectSnapshot(AuthState.user);
    const isSchoolUser = user?.roleGroup === UserGroup.SchoolUser;
    const isClinicUser = user?.roleGroup === UserGroup.ClinicUser;
    const organizationId = user?.organization?.id;
    const userId = user?.id;

    if ((isSchoolUser || isClinicUser) && !userId)
      throw new Error("Could not get user id from store");
    else if (!isSchoolUser && !isClinicUser && !organizationId)
      throw new Error("Could not get organization id from store");

    return iif(
      () => isSchoolUser,
      this.dashboardService.dashboardSchoolStatisticGet(),
      this.dashboardService.dashboardClinicStatisticGet(),
    ).pipe(
      tap((response) =>
        ctx.patchState({ organizationStatisticsResponse: response }),
      ),
      finalize(() => ctx.patchState({ dataLoading: false })),
    );
  }
}
